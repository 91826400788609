import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tabs,
    Tab,
    Box
} from '@mui/material';

function ClientDetails() {
    const { id } = useParams(); // Get client ID from the URL
    const [client, setClient] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [tabValue, setTabValue] = useState(0);

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    useEffect(() => {
        fetchClientDetails();
        fetchClientJobs();
        fetchClientInvoices();
    }, [id]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const fetchClientDetails = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/clients/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setClient(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client details');
        }
    };

    const fetchClientJobs = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/clients/${id}/jobs`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setJobs(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client jobs');
        }
    };

    const fetchClientInvoices = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/clients/${id}/invoices`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setInvoices(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client invoices');
        }
    };

    const handleAuthError = (error, message) => {
        if (error.response?.status === 401 && error.response.data.error === 'Token expired') {
            console.error(`${message}: Token expired. Redirecting to login.`);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('token'); // Clear the invalid token
            window.location.href = '/login'; // Redirect to login page
        } else {
            console.error(`${message}:`, error.response?.data || error.message);
        }
    };

    if (!client) {
        return <Typography>Loading client details...</Typography>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Client Details: {client.name}
            </Typography>
            <Typography variant="body1">Address: {client.address}</Typography>
            <Typography variant="body1">Phone: {client.phone}</Typography>
            <Typography variant="body1">Email: {client.email}</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="client details tabs">
                    <Tab label="Jobs" />
                    <Tab label="Invoices" />
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Job Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobs.map((job) => (
                                <TableRow key={job.id}>
                                    <TableCell>{job.name}</TableCell>
                                    <TableCell>{job.status}</TableCell>
                                    <TableCell>{job.start_date}</TableCell>
                                    <TableCell>{job.end_date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {tabValue === 1 && (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Invoice ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices.map((invoice) => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.id}</TableCell>
                                    <TableCell>{invoice.date}</TableCell>
                                    <TableCell>{invoice.amount}</TableCell>
                                    <TableCell>{invoice.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
}

export default ClientDetails;
