import React, { useEffect, useRef, useState } from 'react';
import { loadGoogleMapsScript } from './googleMapsLoader';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const AutoCompleteInput = ({ onPlaceSelected, placeholder = "Start typing an address..." }) => {
const inputRef = useRef(null);
const autocompleteRef = useRef(null);
const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    loadGoogleMapsScript(apiKey).then((google) => {
      if (inputRef.current && !autocompleteRef.current) {
        autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
          types: ['address'], // or use ['(cities)'] or [] for all
          componentRestrictions: { country: 'us' } // optional
        });
        
        autocompleteRef.current.addListener('place_changed', () => {
          const place = autocompleteRef.current.getPlace();
          const address = place.formatted_address || place.name;
          setInputValue(address);
          if (onPlaceSelected) {
            onPlaceSelected(place);
          }
        });
      }
    });
  }, [apiKey]);

  return (
    <input
    label="Address"
    name="address"
    fullWidth
    margin="normal"
    ref={inputRef}
    value={inputValue}
    margin="normal"
    onChange={(e) => setInputValue(e.target.value)}
    placeholder={placeholder}
    style={{ padding: '10px', width: '100%' }}
    />
  );
};

export default AutoCompleteInput;