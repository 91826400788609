import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const ChatContainer = styled(Paper)({
  padding: '20px',
  height: '500px',
  overflowY: 'auto',
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0,0,0,0.15)',
  display: 'flex',
  flexDirection: 'column-reverse', // Keeps the latest messages at the bottom
});

const CommandInput = styled(TextField)({
  flexGrow: 1, // Allows the input field to take up available space
  marginRight: '10px',
});

const SendButton = styled(Button)({
  width: '100px',
  height: '56px',
  backgroundColor: '#1976d2',
  '&:hover': {
    backgroundColor: '#115293',
  },
});

function Dashboard() {
  // 1. Create or retrieve a sessionId for maintaining conversation context
  const [sessionId] = useState(() => {
    const existingId = localStorage.getItem('sessionId');
    if (existingId) return existingId;
    const randomId = Math.random().toString(36).substring(2, 10);
    localStorage.setItem('sessionId', randomId);
    return randomId;
  });

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleSendCommand = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      setLoading(true);

      try {
        // 2. Retrieve the JWT token from localStorage
        const token = localStorage.getItem('token');
        // If your token is stored under a different name, adjust accordingly

        // 3. Send the request with Authorization header
        const response = await axios.post(
          '/backend/api/ask',
          { message: input, sessionId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('API Response:', response.data);

        // 4. Handle the AI's response
        if (response.data && response.data.message) {
          const aiResponse = { text: response.data.message, sender: 'ai' };
          setMessages((prevMessages) => [...prevMessages, aiResponse]);

          // Check if the AI is asking for any missing fields
          if (response.data.missingField) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: `Please provide the ${response.data.missingField} for the client.`, sender: 'ai' },
            ]);
          }
        } else {
          console.warn('Unexpected API response:', response.data);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'No response from AI.', sender: 'ai' },
          ]);
        }
      } catch (error) {
        console.error('Error fetching AI response:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Error getting response. Try again.', sender: 'ai' },
        ]);
      } finally {
        setLoading(false); // Hide loading indicator
      }
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        AI Assistant Chat
      </Typography>
      <ChatContainer>
        <List>
          {messages.map((message, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={message.text}
                primaryTypographyProps={{
                  style: {
                    textAlign: message.sender === 'ai' ? 'left' : 'right',
                    fontWeight: message.sender === 'ai' ? 'normal' : 'bold',
                    color: message.sender === 'ai' ? '#333' : '#1976d2',
                  },
                }}
              />
            </ListItem>
          ))}
          {loading && (
            <ListItem>
              <CircularProgress size={24} />
            </ListItem>
          )}
        </List>
      </ChatContainer>
      <Box display="flex" alignItems="center" mt={2}>
        <CommandInput
          variant="outlined"
          placeholder="Type your command..."
          value={input}
          onChange={handleInputChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSendCommand();
            }
          }}
        />
        <SendButton onClick={handleSendCommand} variant="contained">
          Send
        </SendButton>
      </Box>
    </Container>
  );
}

export default Dashboard;
