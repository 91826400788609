import React from 'react';
import { Avatar } from '@mui/material';

// Function to generate Google Maps image URL
const getMapImageUrl = (address, lat, lng, apiKey) => {
    const formattedAddress = encodeURIComponent(address);
    //return `https://maps.googleapis.com/maps/api/staticmap?center=${formattedAddress}&zoom=15&size=200x200&maptype=roadmap&key=${apiKey}`;
    //return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=40.5394434,-74.4028223&key=${apiKey}`;
    if(lat != '' && lng != ''){
        return `https://maps.googleapis.com/maps/api/streetview?size=600x400&location=${lat},${lng}&key=${apiKey}`;
    }else{
        return `https://maps.googleapis.com/maps/api/staticmap?center=${formattedAddress}&zoom=15&size=200x200&maptype=roadmap&key=${apiKey}`;
    }
    
    //return `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=place_id:EihBcHBsZSBQYXJrIFdheSwgQ3VwZXJ0aW5vLCBDQSA5NTAxNCwgVVNBIi4qLAoUChIJTZmAhJC1j4ARbyia3aT-W-cSFAoSCat30xtXtI-AEbR44aPhP005&key=${apiKey}`;

};

const ClientAvatar = ({ address, lat, lng }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;  // Ensure this key is kept secure
    //const mapImageUrl = getMapImageUrl(address, apiKey);
    const mapImageUrl = getMapImageUrl(address,lat,lng, apiKey);
    return <Avatar alt="Client Location" src={mapImageUrl} />;
};

export default ClientAvatar;
