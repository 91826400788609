import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    CssBaseline,
    Box,
} from '@mui/material';

const drawerWidth = 240;

function UserLayout() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* Top AppBar */}
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        User Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Left Sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                <Divider />
                <List>
                    {/* Reordered List Items */}
                    <ListItem button component={Link} to="/user/dashboard">
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/calendar">
                        <ListItemText primary="Calendar" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/clients">
                        <ListItemText primary="Clients" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/estimates">
                        <ListItemText primary="Estimates" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/jobs">
                        <ListItemText primary="Jobs" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/invoices">
                        <ListItemText primary="Invoices" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/expenses">
                        <ListItemText primary="Expenses" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/reports">
                        <ListItemText primary="Reports" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/team-members">
                        <ListItemText primary="Team Members" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/services">
                        <ListItemText primary="Services" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/tickets">
                        <ListItemText primary="Tickets" />
                    </ListItem>
                </List>
            </Drawer>

            {/* Main Content Area */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                }}
            >
                <Toolbar />
                {/* Outlet will render the current page content */}
                <Outlet />
            </Box>
        </Box>
    );
}

export default UserLayout;
