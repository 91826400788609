import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Avatar,
    Stack,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ClientAvatar from './ClientAvatar';
import AutoCompleteInput from './AutoCompleteInput';

// Function to generate initials for the avatar
const getAvatarInitials = (name) => {
    if (!name) return 'C'; // Default to 'C' if no name is provided
    const initials = name
        .split(' ') // Split the name by spaces
        .map((n) => n[0]) // Take the first letter of each part
        .join(''); // Join them together
    return initials.substring(0, 2).toUpperCase(); // Limit to two letters
};

function Clients() {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    // Fetch clients from the backend
    const fetchClients = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/clients`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            setClients(response.data);
        } catch (err) {
            if (err.response?.status === 403 || err.response?.status === 401) {
                console.error('Access denied. Attempting token refresh.');
                await refreshAuthToken();
            } else {
                console.error('Error fetching clients:', err.message);
                setError(err.message || 'Failed to fetch clients.');
            }
        }
    }, [baseURL]);

    // Refresh the authentication token
    const refreshAuthToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) throw new Error('Refresh token missing. Please log in again.');

            const response = await axios.post(`${baseURL}/refresh-token`, {}, {
                headers: { Authorization: `Bearer ${refreshToken}` },
            });

            const { accessToken } = response.data;
            localStorage.setItem('accessToken', accessToken);
            console.log('Access token refreshed successfully.');
            fetchClients(); // Retry fetching clients after refreshing token
        } catch (err) {
            console.error('Error refreshing token:', err.message);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            navigate('/login'); // Redirect to login if refresh fails
        }
    };

    useEffect(() => {
        fetchClients();
    }, [fetchClients]); // Dependency ensures fetchClients runs on mount

    const handleClickOpen = (client = null) => {
        setCurrentClient(client);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentClient(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentClient({ ...currentClient, [name]: value });
    };
    
    const handlePlaceSelected = (place) => {
        console.log('Selected Place:', place);
        //setCurrentClient( {...currentClient, address:place.formatted_address} );
        // Update the client's address with the selected formatted address
        const formattedAddress = place.formatted_address;
        // Optionally, also store the lat/lng if you want to use it later for Street View
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setCurrentClient({
        ...currentClient,
        address: formattedAddress,
        latitude: lat,
        longitude: lng
        });
        console.log(currentClient);
       
    };

    const handleSaveClient = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');
            console.log(currentClient);

            // If currentClient has an ID, we're editing; otherwise, creating
            if (currentClient?.id) {
                await axios.put(`${baseURL}/clients/${currentClient.id}`, currentClient, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
            } else {
                await axios.post(`${baseURL}/clients`, currentClient, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
            }
            fetchClients();
            handleClose();
        } catch (err) {
            console.error('Error saving client:', err.response?.data || err.message);
            setError(err.message || 'Failed to save client.');
        }
    };

    const handleDeleteClient = async (id) => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            await axios.delete(`${baseURL}/clients/${id}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            fetchClients();
        } catch (err) {
            console.error('Error deleting client:', err.response?.data || err.message);
            setError(err.message || 'Failed to delete client.');
        }
    };

    const handleRowClick = (client) => {
        navigate(`/user/clients/${client.id}`);
    };

    // Simple search by name
    const filteredClients = clients.filter((client) =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Clients
            </Typography>
            {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
            <TextField
                label="Search Clients"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
    
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredClients.map((client) => (
                            <TableRow
                                key={client.id}
                                onClick={() => handleRowClick(client)}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <ClientAvatar address={client.address} lat={client.lat} lng={client.lng} />
                                        <Typography>{client.name}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>{client.address}</TableCell>
                                <TableCell>{client.phone}</TableCell>
                                <TableCell>{client.email}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickOpen(client);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClient(client.id);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentClient?.id ? 'Edit Client' : 'Add Client'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentClient?.name || ''}
                        onChange={handleInputChange}
                    />
                    <AutoCompleteInput
                        
                        onPlaceSelected={handlePlaceSelected}
                     />
                     
                     <TextField
                        label="Address"
                        name="address"
                        fullWidth
                        margin="normal"
                        value={currentClient?.address || ''}
                        onChange={handleInputChange}
                    />
                    
                    <TextField
                        label="Phone"
                        name="phone"
                        fullWidth
                        margin="normal"
                        value={currentClient?.phone || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        fullWidth
                        margin="normal"
                        value={currentClient?.email || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClient} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Clients;
