import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Jobs() {
    const [jobs, setJobs] = useState([]);
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    const fetchJobs = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');
            const response = await axios.get(`${baseURL}/jobs`, { headers: { Authorization: `Bearer ${accessToken}` }});
            setJobs(response.data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            refreshAuthToken();
        }
    }, []);

    const fetchClients = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');
            const response = await axios.get(`${baseURL}/clients`, { headers: { Authorization: `Bearer ${accessToken}` }});
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
            refreshAuthToken();
        }
    }, []);

    const refreshAuthToken = useCallback(async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) throw new Error('Refresh token missing. Please log in again.');
            const response = await axios.post(`${baseURL}/refresh-token`, {}, { headers: { Authorization: `Bearer ${refreshToken}` }});
            const { accessToken } = response.data;
            localStorage.setItem('accessToken', accessToken);
        } catch (error) {
            console.error('Error refreshing token:', error);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        fetchJobs();
        fetchClients();
        const interval = setInterval(() => {
            refreshAuthToken();
        }, 50 * 60 * 1000);
        return () => clearInterval(interval);
    }, [fetchJobs, fetchClients, refreshAuthToken]);

    const handleRowClick = (job) => {
        navigate(`/jobs/${job.id}`);
    };

    const handleClickOpen = () => {
        setCurrentJob({ name: '', description: '', status: 'pending', client_id: '' });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentJob(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentJob({ ...currentJob, [name]: value });
    };

    const handleSaveJob = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');
            const headers = { Authorization: `Bearer ${accessToken}` };
            if (currentJob.id) {
                await axios.put(`${baseURL}/jobs/${currentJob.id}`, currentJob, { headers });
            } else {
                await axios.post(`${baseURL}/jobs`, currentJob, { headers });
            }
            fetchJobs();
            handleClose();
        } catch (error) {
            console.error('Error saving job:', error);
            refreshAuthToken();
        }
    };

    const handleDeleteJob = async (id) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');
            await axios.delete(`${baseURL}/jobs/${id}`, { headers: { Authorization: `Bearer ${accessToken}` }});
            fetchJobs();
        } catch (error) {
            console.error('Error deleting job:', error);
            refreshAuthToken();
        }
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return; // dropped outside the list
        }
        if (source.index === destination.index) {
            return; // dropped in the same place
        }

        const newJobs = Array.from(jobs);
        const [reorderedJob] = newJobs.splice(source.index, 1);
        newJobs.splice(destination.index, 0, reorderedJob);

        setJobs(newJobs);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Jobs
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-jobs">
                    {(provided) => (
                        <TableContainer component={Paper} {...provided.droppableProps} ref={provided.innerRef}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobs.map((job, index) => (
                                        <Draggable key={job.id} draggableId={job.id.toString()} index={index}>
                                            {(provided) => (
                                                <TableRow
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        cursor: 'grab',
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    onClick={() => handleRowClick(job)}
                                                >
                                                    <TableCell>{job.name}</TableCell>
                                                    <TableCell>{job.client_name}</TableCell>
                                                    <TableCell>{job.status}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={(e) => {
                                                            e.stopPropagation();
                                                            setCurrentJob(job);
                                                            setOpen(true);
                                                        }}><Edit /></IconButton>
                                                        <IconButton onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteJob(job.id);
                                                        }}><Delete /></IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Droppable>
            </DragDropContext>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={handleClickOpen}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentJob?.id ? 'Edit Job' : 'Add Job'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentJob?.name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={currentJob?.description || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        select
                        label="Client"
                        name="client_id"
                        fullWidth
                        margin="normal"
                        value={currentJob?.client_id || ''}
                        onChange={handleInputChange}
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Status"
                        name="status"
                        fullWidth
                        margin="normal"
                        value={currentJob?.status || ''}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="in-progress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                    </TextField>
                    <TextField
                        label="Start Date"
                        name="start_date"
                        type="date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={currentJob?.start_date || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="End Date"
                        name="end_date"
                        type="date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={currentJob?.end_date || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveJob} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Jobs;
