import React, { useState, useEffect } from 'react';
import axios from 'axios';

function QuickbookCallback() {
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';
    useEffect(() => {
        checkQuickBooksData();
    }, []);
    
    // Check QuickBooks connection status
    const checkQuickBooksData = async () => {
         const queryParameters = new URLSearchParams(window.location.search);
         const code = queryParameters.get("code");
         const state = queryParameters.get("state");
         const realmId = queryParameters.get("realmId");
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/quickbooks/callback?code=${code}&state=${state}&realm_id=${realmId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            window.location.href = '/user/expenses'; // Redirect to expenses page
            
        } catch (error) {
            console.error('Error checking QuickBooks connection:', error.response?.data || error.message);
        }
        
    }
}

export default QuickbookCallback;